var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClickOutside,
          expression: "handleClickOutside",
        },
      ],
      staticClass: "relative",
      attrs: { "data-cy": "actionMenu" },
    },
    [
      _c(
        "button",
        {
          staticClass: "relative z-10 rounded focus:outline-none",
          class: _vm.buttonBackgroundColor,
          on: {
            click: function ($event) {
              _vm.menuOpen = !_vm.menuOpen
            },
          },
        },
        [
          _vm._t("default", function () {
            return [
              _c(
                "div",
                { staticClass: "flex items-center px-4 py-1" },
                [
                  _c(
                    "span",
                    { staticClass: "pr-2", class: _vm.buttonTextColor },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c("icon-component", {
                    staticClass: "fill-current",
                    class: _vm.buttonTextColor,
                    attrs: { name: "chevronDown", height: 24, width: 24 },
                  }),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm.menuOpen
        ? _c(
            "div",
            {
              staticClass:
                "absolute z-20 mt-2 border rounded-md shadow-lg min-w-25",
              class: [
                {
                  "left-0": _vm.alignment === "right",
                  "right-0": _vm.alignment === "left",
                },
                "w-" + _vm.menuWidth,
              ],
            },
            [
              _c("div", { staticClass: "bg-white rounded-md shadow-xs" }, [
                _c(
                  "div",
                  {
                    staticClass: "py-1",
                    class: { "overflow-y-scroll h-96": _vm.isScrollable },
                  },
                  _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "button",
                      {
                        key: index,
                        staticClass:
                          "flex items-center w-full p-4 text-left cursor-pointer val-button-blue hover:bg-tyler-hover-highlight focus:outline-none whitespace-nowrap",
                        class: {
                          "border-t-2 border-gray-200": item.hasSeparator,
                        },
                        attrs: {
                          "data-cy": "menuItem-" + index,
                          role: "menuitem",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onMenuItemClickHandler(item)
                          },
                        },
                      },
                      [
                        item.icon
                          ? _c("icon-component", {
                              attrs: {
                                name: item.icon.name,
                                width: item.icon.width || 16,
                                height: item.icon.height || 16,
                                fill: item.icon.fill,
                                stroke: item.icon.stroke,
                              },
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass: "px-1 text-sm",
                            class: _vm.dropDownTextColor,
                          },
                          [_vm._v(" " + _vm._s(item.displayText) + " ")]
                        ),
                        _vm.showNumberSelected || item.showCount
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pl-6 ml-auto text-sm text-blue-500",
                              },
                              [_vm._v(" " + _vm._s(item.count) + " selected ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }