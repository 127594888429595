
import clickOutside from '@/util/directives/clickOutside';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<SlideOutComponent>({
  directives: {
    clickOutside
  }
})
export default class SlideOutComponent extends Vue {
  @Prop({
    default: false
  })
  readonly open!: boolean;

  @Prop({ default: '3/5' })
  width!: string;

  get isOpen(): boolean {
    return this.open;
  }

  handleClose(): void {
    if (!this.open) {
      this.$emit('close');
    }
  }
}
