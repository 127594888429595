var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "fixed top-0 bottom-0 right-0 z-50 h-full overflow-y-scroll bg-white border-l-2 transition-all duration-300 ease-in-out transform",
      class: [
        _vm.isOpen ? "translate-x-0" : "translate-x-full",
        "w-" + _vm.width,
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }