
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { ActionMenuItem } from '@/models/ActionMenuItem';
import IconComponent from '@/components/IconComponent.vue';
import clickOutside from '@/util/directives/clickOutside';

@Component<ActionMenuComponent>({
  components: { IconComponent },
  directives: {
    clickOutside
  }
})
export default class ActionMenuComponent extends Vue {
  @Prop({
    default: () => []
  })
  readonly items!: ActionMenuItem[];

  @Prop({
    type: String as PropType<'left' | 'right'>,
    default: () => 'left'
  })
  readonly alignment!: 'left' | 'right';

  @Prop({
    default: () => 'auto'
  })
  readonly menuWidth!: string;

  @Prop({
    default: () => 'Action'
  })
  readonly title!: string;

  @Prop({
    default: false
  })
  readonly showNumberSelected!: boolean;

  @Prop({
    default: false
  })
  readonly isScrollable!: boolean;

  @Prop({
    default: () => 'text-white'
  })
  readonly buttonTextColor!: string;

  @Prop({
    default: () => 'text-black'
  })
  readonly dropDownTextColor!: string;

  @Prop({
    default: () => 'bg-val-button-blue'
  })
  readonly buttonBackgroundColor!: string;

  menuOpen = false;

  onMenuItemClickHandler(item: ActionMenuItem): void {
    this.$emit('itemClick', item);
    this.menuOpen = false;
  }

  handleClickOutside(): void {
    this.menuOpen = false;
  }
}
